import React from 'react';

export const MAX_BOOKINGS_COUNT = 20;

export const QUESTIONS = [
  'Do you suffer from heart disease, high blood pressure or cardiovascular problems?',
  'Is there a history of heart disease in your family?',
  'Has your doctor ever said you have high blood pressure?',
  'Do you ever have pains in your heart and chest after undergoing exertion?',
  'Do you often get headaches, feel faint or dizzy?',
  'Do you suffer from pain or limited movement in any joints or bones?',
  'Is this pain aggravated by exercise or might be made worse by it?',
  'Are you pregnant?',
  'Do you have any other condition which might affect your ability to participate in exercise?',
];

const isDevelopment = process.env.NODE_ENV === 'development';

export const COUPLES_PLANS = {
  'gym-and-classes': [
    {
      elementId: 'six-monthly-couples-gym-and-classes',
      name: '6 Monthly - Couples - Gym & Classes',
      frequency: 'per 6 months',
      frequencyLabel: '6 monthly',
      price: '£180',
      priceValue: 180,
      planId: null,
      planLabel: 'Gym & Classes',
      ageRange: '16 years +',
      level: 'gym-and-classes',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul className="gs-u-mb">
            <li>24 hour access</li>
          </ul>
          <span className="gs-u-display-block gs-u-mb">
            <i className="fa fa-users gs-u-mr" />
            <b>Up to 15 classes weekly</b> - subject to booking and availability
          </span>
        </div>
      ),
    },
    {
      elementId: 'annual-couples-gym-and-classes',
      name: 'Annual - Couples - Gym & Classes',
      frequency: 'per year',
      frequencyLabel: 'annually',
      price: '£360',
      priceValue: 360,
      planId: null,
      planLabel: 'Gym & Classes',
      ageRange: '16 years +',
      level: 'gym-and-classes',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul className="gs-u-mb">
            <li>24 hour access</li>
          </ul>
          <span className="gs-u-display-block gs-u-mb">
            <i className="fa fa-users gs-u-mr" />
            <b>Up to 15 classes weekly</b> - subject to booking and availability
          </span>
        </div>
      ),
    },
  ],
  'gym-only': [
    {
      elementId: 'six-monthly-couples-gym-only',
      name: '6 Monthly - Couples - Gym Only',
      frequency: 'per 6 months',
      frequencyLabel: '6 monthly',
      price: '£135',
      priceValue: 135,
      planId: null,
      planLabel: 'Gym Only',
      ageRange: '16 years +',
      level: 'gym-only',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul>
            <li>24 hour access</li>
          </ul>
        </div>
      ),
    },
    {
      elementId: 'annual-couples-gym-only',
      name: 'Annual - Couples - Gym Only',
      frequency: 'per year',
      frequencyLabel: 'annually',
      price: '£270',
      priceValue: 270,
      planId: null,
      planLabel: 'Gym Only',
      ageRange: '16 years +',
      level: 'gym-only',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul>
            <li>24 hour access</li>
          </ul>
        </div>
      ),
    },
  ],
  'gym-and-classes-gym-and-classes': [
    {
      elementId: 'six-monthly-couples-gym-and-classes',
      name: '6 Monthly - Couples - Gym & Classes',
      frequency: 'per 6 months',
      frequencyLabel: '6 monthly',
      price: '£360',
      priceValue: 360,
      planId: isDevelopment
        ? 'P-7J518286342376219MXBKAPQ'
        : 'P-3MV01024404541437MXBJ4RY',
      planLabel: 'Gym & Classes',
      ageRange: '16 years +',
      level: 'gym-and-classes',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul className="gs-u-mb">
            <li>24 hour access</li>
          </ul>
          <span className="gs-u-display-block gs-u-mb">
            <i className="fa fa-users gs-u-mr" />
            <b>Up to 15 classes weekly</b> - subject to booking and availability
          </span>
        </div>
      ),
    },
    {
      elementId: 'annual-couples-gym-and-classes',
      name: 'Annual - Couples - Gym & Classes',
      frequency: 'per year',
      frequencyLabel: 'annually',
      price: '£720',
      priceValue: 720,
      planId: isDevelopment
        ? 'P-6VX9962284011071FMXBJKLA'
        : 'P-0FL058730W4722539MXBJ4EQ',
      planLabel: 'Gym & Classes',
      ageRange: '16 years +',
      level: 'gym-and-classes',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul className="gs-u-mb">
            <li>24 hour access</li>
          </ul>
          <span className="gs-u-display-block gs-u-mb">
            <i className="fa fa-users gs-u-mr" />
            <b>Up to 15 classes weekly</b> - subject to booking and availability
          </span>
        </div>
      ),
    },
  ],
  'gym-only-gym-only': [
    {
      elementId: 'six-monthly-couples-gym-only',
      name: '6 Monthly - Couples - Gym Only',
      frequency: 'per 6 months',
      frequencyLabel: '6 monthly',
      price: '£270',
      priceValue: 270,
      planId: isDevelopment
        ? 'P-7XH43627163272642MXBJLWI'
        : 'P-9TA05201UT906832PMXBJ5IY',
      planLabel: 'Gym Only',
      ageRange: '16 years +',
      level: 'gym-only',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul>
            <li>24 hour access</li>
          </ul>
        </div>
      ),
    },
    {
      elementId: 'annual-couples-gym-only',
      name: 'Annual - Couples - Gym Only',
      frequency: 'per year',
      frequencyLabel: 'annually',
      price: '£540',
      priceValue: 540,
      planId: isDevelopment
        ? 'P-9BA47275W3120940FMXBKA4Y'
        : 'P-4T339483YD107003VMXBJ45A',
      planLabel: 'Gym Only',
      ageRange: '16 years +',
      level: 'gym-only',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul>
            <li>24 hour access</li>
          </ul>
        </div>
      ),
    },
  ],
  'gym-and-classes-gym-only': [
    {
      elementId: 'six-monthly-couples-gym-and-classes-gym-only',
      name: '6 Monthly - Couples - Gym & Classes - Gym Only',
      frequency: 'per 6 months',
      frequencyLabel: '6 monthly',
      price: '£315',
      priceValue: 315,
      planId: isDevelopment
        ? 'P-44N87030GV047512SMXBJMVA'
        : 'P-0LU51423R6771080YMXBJ6AY',
      planLabel: 'Gym & Classes and Gym Only',
      ageRange: '16 years +',
      level: 'gym-only',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul>
            <li>24 hour access</li>
          </ul>
        </div>
      ),
    },
    {
      elementId: 'annual-couples-gym-and-classes-gym-only',
      name: 'Annual - Couples - Gym & Classes - Gym Only',
      frequency: 'per year',
      frequencyLabel: 'annually',
      price: '£630',
      priceValue: 630,
      planId: isDevelopment
        ? 'P-3KP364743R909554WMXBJMIQ'
        : 'P-9XM92827AH535382FMXBJ5VY',
      planLabel: 'Gym & Classes and Gym Only',
      ageRange: '16 years +',
      level: 'gym-only',
      descriptionNode: (
        <div className="plan-description gel-long-primer">
          <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
          every day:
          <ul>
            <li>24 hour access</li>
          </ul>
        </div>
      ),
    },
  ],
};

export const PLANS = {
  adult: {
    'gym-and-classes': [
      {
        elementId: 'monthly-adult-gym-and-classes',
        name: 'Monthly - Adult - Gym & Classes',
        frequency: 'per month',
        frequencyLabel: 'monthly',
        price: '£40',
        planId: isDevelopment
          ? 'P-1U565955WV7332735MWKXKOA'
          : 'P-1MS57630VT1095347L5AOXRY',
        planLabel: 'Gym & Classes',
        ageRange: '16 years +',
        level: 'gym-and-classes',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul className="gs-u-mb">
              <li>24 hour access</li>
            </ul>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-users gs-u-mr" />
              <b>Up to 15 classes weekly</b> - subject to booking and
              availability
            </span>
          </div>
        ),
      },
      {
        elementId: 'six-monthly-adult-gym-and-classes',
        name: '6 Monthly - Adult - Gym & Classes',
        frequency: 'per 6 months',
        frequencyLabel: '6 monthly',
        price: '£200',
        planId: isDevelopment
          ? 'P-5TY11899LA899101LMWVFF2A'
          : 'P-3KM037453W680102YMWVE6IQ',
        planLabel: 'Gym & Classes',
        ageRange: '16 years +',
        level: 'gym-and-classes',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul className="gs-u-mb">
              <li>24 hour access</li>
            </ul>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-users gs-u-mr" />
              <b>Up to 15 classes weekly</b> - subject to booking and
              availability
            </span>
          </div>
        ),
      },
      {
        elementId: 'annual-adult-gym-and-classes',
        name: 'Annual - Adult - Gym & Classes',
        frequency: 'per year',
        frequencyLabel: 'annually',
        price: '£400',
        planId: isDevelopment
          ? 'P-3CY024584U4232531MWKXK6Q'
          : 'P-0WK052447N633953FL5APA7I',
        planLabel: 'Gym & Classes',
        ageRange: '16 years +',
        level: 'gym-and-classes',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul className="gs-u-mb">
              <li>24 hour access</li>
            </ul>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-users gs-u-mr" />
              <b>Up to 15 classes weekly</b> - subject to booking and
              availability
            </span>
          </div>
        ),
      },
    ],
    'gym-only': [
      {
        elementId: 'monthly-adult-gym-only',
        name: 'Monthly - Adult - Gym Only',
        frequency: 'per month',
        frequencyLabel: 'monthly',
        price: '£30',
        planId: isDevelopment
          ? 'P-0YA84456D9026693MMWKXLNY'
          : 'P-7LK27404624902336L5AO5VA',
        planLabel: 'Gym Only',
        ageRange: '16 years +',
        level: 'gym-only',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul>
              <li>24 hour access</li>
            </ul>
          </div>
        ),
      },
      {
        elementId: 'six-monthly-adult-gym-only',
        name: '6 Monthly - Adult - Gym Only',
        frequency: 'per 6 months',
        frequencyLabel: '6 monthly',
        price: '£150',
        planId: isDevelopment
          ? 'P-47H24853AF7022119MWVFGEQ'
          : 'P-3DF14950L7479514TMWVE62I',
        planLabel: 'Gym Only',
        ageRange: '16 years +',
        level: 'gym-only',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul>
              <li>24 hour access</li>
            </ul>
          </div>
        ),
      },
      {
        elementId: 'annual-adult-gym-only',
        name: 'Annual - Adult - Gym Only',
        frequency: 'per year',
        frequencyLabel: 'annually',
        price: '£300',
        planId: isDevelopment
          ? 'P-4AL06575YT201003RMWKXLVI'
          : 'P-5RB89850UX937941LL5APB2Y',
        planLabel: 'Gym Only',
        ageRange: '16 years +',
        level: 'gym-only',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul>
              <li>24 hour access</li>
            </ul>
          </div>
        ),
      },
    ],
  },
  child: {
    'gym-and-classes': [
      {
        elementId: 'monthly-child-gym-and-classes',
        name: 'Monthly - Child - Gym & Classes',
        frequency: 'per month',
        frequencyLabel: 'monthly',
        price: '£20',
        planId: 'P-5R1527228X332742LL5AO6WI',
        planLabel: 'Gym & Classes',
        ageRange: '12 - 15 years',
        level: 'gym-and-classes',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Adult supervision</b> - must be supervised by an adult at all
              times
            </span>
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul className="gs-u-mb">
              <li>24 hour access</li>
            </ul>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-users gs-u-mr" />
              <b>Up to 15 classes weekly</b> - subject to booking and
              availability
            </span>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Wednesday Teen Sessions</b> <br /> 4pm - 5pm with staff on hand
              to provide supervision and guidance
            </span>
          </div>
        ),
      },
      {
        elementId: 'annual-child-gym-and-classes',
        name: 'Annual - Child - Gym & Classes',
        frequency: 'per year',
        frequencyLabel: 'annually',
        price: '£200',
        planId: 'P-8C526659HP0699936L5APCTY',
        planLabel: 'Gym & Classes',
        ageRange: '12 - 15 years',
        level: 'gym-and-classes',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Adult supervision</b> - must be supervised by an adult at all
              times
            </span>
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul className="gs-u-mb">
              <li>24 hour access</li>
            </ul>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-users gs-u-mr" />
              <b>Up to 15 classes weekly</b> - subject to booking and
              availability
            </span>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Wednesday Teen Sessions</b> <br /> 4pm - 5pm with staff on hand
              to provide supervision and guidance
            </span>
          </div>
        ),
      },
    ],
    'gym-only': [
      {
        elementId: 'monthly-child-gym-only',
        name: 'Monthly - Child - Gym Only',
        frequency: 'per month',
        frequencyLabel: 'monthly',
        price: '£15',
        planId: 'P-26B77895VU467983HL5AO7KQ',
        planLabel: 'Gym Only',
        ageRange: '12 - 15 years',
        level: 'gym-only',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Adult supervision</b> - must be supervised by an adult at all
              times
            </span>
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul>
              <li>24 hour access</li>
            </ul>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Wednesday Teen Sessions</b> <br /> 4pm - 5pm with staff on hand
              to provide supervision and guidance
            </span>
          </div>
        ),
      },
      {
        elementId: 'annual-child-gym-only',
        name: 'Annual - Child - Gym Only',
        frequency: 'per year',
        frequencyLabel: 'annually',
        price: '£150',
        planId: 'P-4TN906805U0667437L5APDGI',
        planLabel: 'Gym Only',
        ageRange: '12 - 15 years',
        level: 'gym-only',
        descriptionNode: (
          <div className="plan-description gel-long-primer">
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Adult supervision</b> - must be supervised by an adult at all
              times
            </span>
            <i className="fa fa-calendar gs-u-mr" /> <b>Access to the gym</b>{' '}
            every day:
            <ul>
              <li>24 hour access</li>
            </ul>
            <span className="gs-u-display-block gs-u-mb">
              <i className="fa fa-user gs-u-mr" />
              <b>Wednesday Teen Sessions</b> <br /> 4pm - 5pm with staff on hand
              to provide supervision and guidance
            </span>
          </div>
        ),
      },
    ],
  },
};

export const oneTimePlans = ['gym-and-classes-6-months', 'gym-only-6-months'];

export const CLASSES = [
  {
    name: 'Stay strong with Liz',
    description: (
      <>
        <p className="gs-u-mb">
          Stay strong with Liz is aimed, primarily at slightly older people who
          wish to reduce the natural loss of muscle mass and mobility which
          accompanies aging and continue to be able to perform necessary
          everyday tasks for as long as possible.
        </p>
        <p>
          Classes are based around basic controlled functional movements,
          progressive resistance and isometric exercises. It doesn't matter what
          level you are currently at as all the movements are scaled up or down
          individually.
        </p>
      </>
    ),
  },
  {
    name: 'L.B.T',
    description: (
      <>
        <p className="gs-u-mb">
          Legs, Bums and Tums is a popular group exercise that targets legs,
          glutes, abs and core. Great for burning fat and improving shape and
          tone. This is a low impact class suitable for all fitness levels and
          ages.
        </p>
        <p>
          L.B.T will improve your stamina, endurance and strength, with or
          without weights. A great way to workout with friends!
        </p>
      </>
    ),
  },
  {
    name: 'Workout',
    videoLink:
      'https://s3.eu-west-1.amazonaws.com/thegymatmoffat.co.uk/Work+out.mp4',
    description: (
      <>
        <p>
          Workout is a gym based class in which one of our trained instructors
          will provide you with a plan to work through. This is done typically
          in groups so you won't be on your own. You will then finish with a
          different fun challenge each one targeting a different area of
          fitness. Workout is on twice a week with 1 class focused on upper body
          and the other on lower body. Whatever your level, Workout will improve
          your endurance, strength and balance.
        </p>
      </>
    ),
  },
  {
    name: '50/50',
    description: (
      <>
        <p className="gs-u-mb">
          50/50 is a 2 in 1 class, for example spin and then circuits, which
          changes and adapts to the ability of the people in it. A dynamic class
          which consists of aerobic and body conditioning.
        </p>
        <p>
          50/50 will strengthen, increase endurance, burn calories and keep
          muscles toned.
        </p>
      </>
    ),
  },
  {
    name: 'Circuits',
    videoLink:
      'https://s3.eu-west-1.amazonaws.com/thegymatmoffat.co.uk/Circuits.mp4',
    description: (
      <p>
        Circuit training is a form of body conditioning that involves endurance
        training, resistance training, high-intensity aerobics, and exercises
        performed in a circuit, similar to High-intensity interval training. It
        targets strength building and muscular endurance. An exercise "circuit"
        is one completion of all set exercises in the program. When one circuit
        is complete, one begins the first exercise again for the next circuit.
        Traditionally, the time between exercises in circuit training is short,
        often with rapid movement to the next exercise.
      </p>
    ),
  },
  {
    name: 'HITT Cardio / Strength',
    description: (
      <p>
        HITT cardio and Strength are 30-minute high intensity interval training
        (HITT) workouts that improves cardiovascular fitness, increases speed
        and maximizes calorie burn. These workouts uses a variety of body weight
        exercises and at times barbell and weighted exercises if your doing the
        strength version and provides the challenge and intensity you need to
        get results fast.
      </p>
    ),
  },
  {
    name: 'Oh My WOD',
    description: (
      <p>
        A unique feel of a workout. Starting with some technical based work and
        finishing with a cardio bursting workout that will leave you on empty! A
        crossfire inspired workout.
      </p>
    ),
  },
  {
    name: 'Pump',
    description: (
      <p>
        Pump is a fast-paced, barbell-based workout that's specifically designed
        to help you get lean, toned and fit. It uses a combination of motivating
        music, fantastic instructors and scientifically proven moves to help you
        achieve these targets more quickly than you would working out on your
        own.
      </p>
    ),
  },
  {
    name: 'Spin',
    videoLink:
      'https://s3.eu-west-1.amazonaws.com/thegymatmoffat.co.uk/Spin.mp4',
    description: (
      <>
        <p>
          An ideal class for improving your overall fitness. Spin can help build
          strength and cardiovascular fitness. These classes can also help boost
          your mood and get you in the fitness zone. Our spin classes are ideal
          for people with a mixed level of fitness.
        </p>
      </>
    ),
  },
  {
    name: 'Pickleball',
    description: (
      <>
        <p className="gs-u-mb">
          Pickleball is one of the fastest growing sports in the world and
          combines elements of badminton, table tennis, and tennis. Two or four
          players use solid paddles made of wood or composite materials to hit a
          perforated polymer ball over a net. The sport shares features of other
          racquet sports, the dimensions and layout of a badminton court, and a
          net and rules similar to tennis, with a few modifications.
        </p>
        <p>
          It is designed to be low impact, fun and open to all ages and
          abilities, whatever their fitness level. A great way to stay fit and
          meet new people.
        </p>
      </>
    ),
  },
];
