/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import axios from 'axios';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextSection from '../../components/TextSection';
import BookingsHeader from '../../containers/BookingsHeader';
import COURT_HIRE, { courtHireActivities } from '../../courtHire';
import { DAYS, theme } from '../../containers/BookingsModal';
import { isGymMember } from '../../utils/bookings';

function TextFieldWrap(props) {
  const { inputRef, value, onClick, onBlur } = props;
  return (
    <input
      id="court-hire-date"
      ref={inputRef}
      type="text"
      placeholder="Select booking date"
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      readOnly
    />
  );
}

function initPayPalButton({ setPayment, formNode }) {
  window.paypal
    .Buttons({
      style: {
        shape: 'pill',
        color: 'blue',
        layout: 'vertical',
        label: 'paypal',
      },
      // onClick is called when the button is clicked
      onClick: () => {
        // Show a validation error if the checkbox is not checked
        if (!document.getElementById('court-hire-date').value.length) {
          alert('Please select a booking date');
        }
      },
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: 'The Gym at Moffat Court Hire',
              amount: { currency_code: 'GBP', value: 10 },
            },
          ],
        });
      },
      onApprove: (_, actions) => {
        return actions.order.capture().then(details => {
          setPayment(details);
          formNode.current.submitForm();
        });
      },
      onError: err => {
        console.log(err);
      },
    })
    .render('#modal-paypal-button-container');
}

const refurbDays = [
  '31/07/2024',
  '01/08/2024',
  '02/08/2024',
  '03/08/2024',
  '04/08/2024',
];

const shouldDisableRefurbDates = dateString => {
  return refurbDays.includes(dateString);
};

export function disableDays(date) {
  const dateCheck = date.format('DD/MM/YYYY');

  if (dateCheck === '23/06/2024') {
    return true;
  }

  if (shouldDisableRefurbDates(dateCheck)) {
    return true;
  }
}

/**
 * CourtHire component
 *
 * @param {object} props
 */
const CourtHire = () => {
  const user = useSelector(state => state.user);
  const [payment, setPayment] = useState(null);
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const formNode = useRef();

  const setupPayPalButton = () => {
    const buttonContainer = document.getElementById(
      'modal-paypal-button-container'
    );

    if (buttonContainer && !paypalLoaded) {
      initPayPalButton({ setPayment, formNode });
      setPaypalLoaded(true);
    }
  };

  return (
    <React.Fragment>
      <TextSection
        title="Court Hire"
        textHtml={
          user ? (
            <React.Fragment>
              <p className="gs-u-mb++">
                To get started with your court hire, please select the date
                you'd like to book. You can then select your activity and time
                slot (subject to availability).
              </p>
              <div className="classes__wrapper gel-wrap">
                <div className="o-slice-spacing-bottom">
                  <Formik
                    innerRef={formNode}
                    initialValues={{
                      hireDate: '',
                      hireActivity: '',
                      hireTime: '',
                    }}
                    validate={values => {
                      const { hireActivity, hireTime } = values;
                      const errors = {};

                      if (!hireActivity || !hireActivity.length) {
                        errors.hireActivity = 'Activity is required';
                      }

                      if (!hireTime || !hireTime.length) {
                        errors.hireTime = 'Time is required';
                      }

                      return errors;
                    }}
                    onSubmit={async values => {
                      const { hireActivity, hireTime } = values;
                      const timeSlot = activeDate.slots.find(
                        slot => slot.timeLabel === hireTime
                      );

                      const {
                        startHour,
                        startMinutes,
                        endHour,
                        endMinutes,
                      } = timeSlot;
                      bookingStartDate.hour(startHour);
                      bookingStartDate.minute(startMinutes);
                      bookingStartDate.second(0);

                      const bookingEndDate = bookingStartDate.clone();
                      bookingEndDate.hour(endHour);
                      bookingEndDate.minute(endMinutes);

                      const activity = courtHireActivities.find(
                        actv => actv.id === hireActivity
                      );

                      const booking = {
                        userId: user._id,
                        email: user.email,
                        firstName: user.firstName,
                        surName: user.surName,
                        username: user.username,
                        membershipType: user.membershipType,
                        membershipLevel: user.membershipLevel,
                        subscriptionId: user.subscriptionId,
                        phone: user.phone,
                        class: {
                          ...timeSlot,
                          label: `${activity.name} (CH)`,
                          id: 'court-hire',
                          day: bookingStartDate.format('dddd'),
                          activity,
                        },
                        bookingStartDate: bookingStartDate.format(),
                        bookingStartDateEpoch: bookingStartDate.valueOf(),
                        bookingEndDate: bookingEndDate.format(),
                        bookingEndDateEpoch: bookingEndDate.valueOf(),
                      };

                      if (!isGymMember(user) && !payment) {
                        return window.alert('Please pay via PayPal');
                      }

                      if (!isGymMember(user) && payment) {
                        booking.payment = payment;
                      }

                      const { data } = await axios
                        .post('/api/bookings', booking)
                        .catch(err => {
                          const { data: errData } = err.response;
                          console.log(errData);

                          if (errData.error) {
                            return window.alert(errData.error);
                          }

                          if (errData.message) {
                            return window.alert(errData.message);
                          }

                          return window.alert(
                            'Looks like something went wrong there. Please try again.'
                          );
                        });

                      if (data && data.success) {
                        window.location.href =
                          '/court-hire?m=Your court hire is now confirmed. You should receive a confirmation email.';
                      }
                    }}
                  >
                    {({
                      isSubmitting,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      errors,
                      values,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                          <label
                            htmlFor="hireDate"
                            className="gs-u-display-block"
                          >
                            Court hire date *
                          </label>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={theme}>
                              <DatePicker
                                id="hireDate"
                                variant="inline"
                                name="hireDate"
                                format="dddd, Do MMMM"
                                minDate={new Date()}
                                value={bookingStartDate}
                                shouldDisableDate={date => disableDays(date)}
                                onChange={async e => {
                                  const slotsToRender = COURT_HIRE.find(
                                    hireDate =>
                                      hireDate.day === e.format('dddd')
                                  );
                                  setBookingStartDate(e);
                                  setActiveDate(slotsToRender);

                                  const date = e.clone();
                                  date.hour(0);
                                  date.minute(0);
                                  date.second(0);

                                  const { data } = await axios.get(
                                    `/api/time-slots?date=${date.valueOf()}`
                                  );

                                  setTimeSlots(data);
                                }}
                                onBlur={handleBlur}
                                TextFieldComponent={TextFieldWrap}
                                disablePast
                                autoOk
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </fieldset>
                        {bookingStartDate && (
                          <>
                            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                              <label
                                htmlFor="hireActivity"
                                className="gs-u-display-block"
                              >
                                Activity *
                              </label>
                              <select
                                id="hireActivity"
                                type="text"
                                value={values.hireActivity}
                                onChange={e => {
                                  if (!errors.hireTime) {
                                    setupPayPalButton();
                                  }
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                className={
                                  errors.hireActivity && touched.hireActivity
                                    ? 'text-input error'
                                    : 'text-input'
                                }
                              >
                                <option>Select an activity</option>
                                {courtHireActivities.map(activity => (
                                  <option key={activity.id} value={activity.id}>
                                    {activity.name}
                                  </option>
                                ))}
                              </select>

                              {errors.hireActivity && touched.hireActivity && (
                                <div className="input-error">
                                  {errors.hireActivity}
                                </div>
                              )}

                              {values.hireActivity && (
                                <div className="input-feedback gs-u-mt gel-long-primer">
                                  <i>
                                    {
                                      courtHireActivities.find(
                                        activity =>
                                          activity.id === values.hireActivity
                                      ).description
                                    }
                                  </i>
                                </div>
                              )}
                            </fieldset>
                            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                              <label
                                htmlFor="hireTime"
                                className="gs-u-display-block"
                              >
                                Time *
                              </label>
                              <select
                                id="hireTime"
                                type="text"
                                value={values.hireTime}
                                onChange={e => {
                                  if (!errors.hireActivity) {
                                    setupPayPalButton();
                                  }
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                className={
                                  errors.hireTime && touched.hireTime
                                    ? 'text-input error'
                                    : 'text-input'
                                }
                              >
                                <option>Select a time</option>
                                {activeDate.slots.map(slot => (
                                  <option
                                    key={`${activeDate.day}-${slot.timeLabel}`}
                                    disabled={timeSlots.find(
                                      sl =>
                                        sl.class.timeLabel === slot.timeLabel
                                    )}
                                  >
                                    {slot.timeLabel}
                                  </option>
                                ))}
                              </select>

                              {errors.hireTime && touched.hireTime && (
                                <div className="input-error">
                                  {errors.hireTime}
                                </div>
                              )}
                            </fieldset>

                            {isGymMember(user) && (
                              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                                <button
                                  className="button button--filled button--large"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  Book this court
                                </button>
                              </fieldset>
                            )}

                            {!isGymMember(user) && (
                              <fieldset className="gel-layout__item gs-u-mb gel-1/1">
                                {!paypalLoaded && (
                                  <button
                                    className="button button--filled button--full button--large"
                                    type="submit"
                                    disabled
                                  >
                                    Pay with PayPal
                                  </button>
                                )}
                                <div id="smart-button-container">
                                  <div>
                                    <div id="modal-paypal-button-container" />
                                  </div>
                                </div>
                                <span className="label-info gs-u-mt gel-minion gs-u-display-block">
                                  Once you return from PayPal, please wait for
                                  your payment to process.
                                </span>
                              </fieldset>
                            )}
                          </>
                        )}
                        <div className="gs-u-align-left gel-long-primer flex-container">
                          <i className="fa fa-info-circle gs-u-mr gs-u-color-white gel-pica" />
                          <div>
                            <p className="gs-u-mb">
                              Please be aware of your surroundings when playing
                              on the court. Stay away from any equipment around
                              the area and be mindful of other gym users.{' '}
                            </p>

                            <p className="gs-u-mb">
                              Put away all equipment after use and report any
                              faults to a member of staff or via email.
                            </p>

                            <p>Thank you and enjoy!</p>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <BookingsHeader context="court hire" />
          )
        }
      />
    </React.Fragment>
  );
};

export default CourtHire;
