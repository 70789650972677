/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';
import axios from 'axios';
import TextSection from '../components/TextSection';
import BookingsHeader from './BookingsHeader';

import { toggleModal } from '../store/actions/ui';
import { setActiveBooking } from '../store/actions/bookings';
import CLASSES from '../classes';

axios.defaults.withCredentials = true;

const mapStateToProps = state => ({
  user: get(state, 'user'),
});

const mapDispatchToProps = dispatch => ({
  setModalState: isModalOpen => dispatch(toggleModal(isModalOpen)),
  setBookingState: activeBooking => dispatch(setActiveBooking(activeBooking)),
});

const getClass = (props, classItem, day) => {
  const { setModalState, setBookingState, user } = props;
  const { timeLabel, label, isBookable } = classItem;
  const classes = classNames('gs-u-p+ button button--full button--table-cell', {
    'button--disabled': !isBookable,
  });

  const clickHandler = () => {
    setBookingState({
      ...classItem,
      day,
    });
    setModalState(true);
  };

  if (!user) {
    return (
      <>
        <p className="gs-u-p+">
          {timeLabel}
          <b className="gs-u-display-block gs-u-mt-">{label}</b>
          {!isBookable && (
            <span className="gs-u-display-block gel-minion gs-u-mt-">
              (not bookable)
            </span>
          )}
        </p>
      </>
    );
  }

  return (
    <button
      className={classes}
      type="button"
      onClick={clickHandler}
      disabled={!isBookable}
    >
      {timeLabel}
      <b className="gs-u-display-block gs-u-mt-">{label}</b>
      {!isBookable && (
        <span className="gs-u-display-block gel-minion gs-u-mt-">
          (not bookable)
        </span>
      )}
    </button>
  );
};

const renderClasses = props => {
  return (
    <table id="timetable" className="gs-u-mb++">
      <thead>
        <tr className="table-col">
          {CLASSES.map(({ day }) => (
            <th
              key={day}
              className="gel-great-primer-bold table-col gs-u-p gs-u-p+@l"
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Morning */}
        {['slot1', 'slot2', 'slot3', 'slot4'].map((_, morningSlotIndex) => (
          <tr key={`morning-${morningSlotIndex}`}>
            {CLASSES.map(({ day, morningClasses }, index) => {
              const classItem = morningClasses[morningSlotIndex];
              if (classItem) {
                return (
                  <td
                    className="gel-long-primer gs-u-p0"
                    key={`afternoon-${morningSlotIndex}-${index}`}
                  >
                    {getClass(props, classItem, day)}
                  </td>
                );
              }
              return (
                <td
                  className="gs-u-p gs-u-p+@l gel-long-primer"
                  key={`afternoon-${morningSlotIndex}-${index}`}
                />
              );
            })}
          </tr>
        ))}
        {/* Afternoon */}
        {['slot1', 'slot2', 'slot3'].map((_, afternoonSlotIndex) => (
          <tr key={`afternoon-${afternoonSlotIndex}`}>
            {CLASSES.map(({ day, afternoonClasses }, index) => {
              const classItem = afternoonClasses[afternoonSlotIndex];
              if (classItem) {
                return (
                  <td
                    className="gel-long-primer gs-u-p0"
                    key={`afternoon-${afternoonSlotIndex}-${index}`}
                  >
                    {getClass(props, classItem, day)}
                  </td>
                );
              }
              return (
                <td
                  className="gs-u-p gs-u-p+@l gel-long-primer"
                  key={`afternoon-${afternoonSlotIndex}-${index}`}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * BookingsForm container
 *
 * @param {object} props
 */
const BookingsForm = props => {
  const { user } = props;

  return (
    <React.Fragment>
      <section>
        <TextSection
          title="Book your class"
          textHtml={
            user ? (
              <React.Fragment>
                <p className="gs-u-mb++">
                  Below you will find our weekly class timetable. To get started
                  with your booking, please click on the class you'd like to
                  attend. Upon clicking, we'll present a popup for you to fill
                  in a few more details before confirming your booking.
                </p>
              </React.Fragment>
            ) : (
              <BookingsHeader />
            )
          }
        />
        <div className="classes__wrapper gel-wrap">
          <div className="o-slice-spacing-bottom">{renderClasses(props)}</div>
        </div>
      </section>
      <section id="gym">
        <TextSection
          title="Book your gym time"
          textHtml={
            <a className="button button--full gs-u-p+ gs-u-mt" href="/payg">
              Book your gym access
            </a>
          }
        />
      </section>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsForm);
