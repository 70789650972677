/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-target-blank */
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useExpanded } from 'react-table';
import { QUESTIONS } from '../utils/consts';

import {
  getMembershipTypeLabel,
  getMembershipLevelLabel,
} from '../utils/memberships';
import { EditSubscriptionId } from '../components/EditSubscriptionId';

// const paypalUrl = "https://www.paypal.com/myaccount/autopay/connect";
const paypalUrl = 'https://www.paypal.com';

const getPaypalLink = () => {
  return paypalUrl;
  // if (member.subscriptionId) {
  //   return `${paypalUrl}/${member.subscriptionId}`;
  // }
};

const getHealthIssues = member => {
  const questionsWithIssues = [];

  QUESTIONS.forEach((question, index) => {
    const answer = member[`medicalQuestionnaire${index + 1}`];
    const response = {
      question,
      info: member[`medicalQuestionnaire${index + 1}Explain`],
    };

    if (answer === `q${index + 1}-yes`) {
      questionsWithIssues.push(response);
    }
  });

  return questionsWithIssues;
};

const transformMembers = members => {
  return members.map(member => {
    return {
      firstName: member.firstName,
      surName: member.surName,
      email: member.email,
      membershipType: getMembershipTypeLabel(member.membershipType),
      membershipLevel: getMembershipLevelLabel(member.membershipLevel),
      actions: member,
      created_date: member.created_date,
      marketingOptOut: member.marketingOptOut,
      healthIssues: getHealthIssues(member).length > 0,
      partnerMemberId: member.partnerMemberId,
    };
  });
};

function ActionsCell({ value }) {
  let paypalNode = '';

  if (value.subscriptionId) {
    paypalNode = (
      <a
        className="gs-u-display-block gs-u-mb"
        href={getPaypalLink(value)}
        target="_blank"
      >
        <i className="fa fa-paypal icon--paypal gs-u-mr" />
        PayPal
      </a>
    );
  }

  const deleteNode = (
    <a
      className="gs-u-display-block delete gs-u-mb"
      href={`/api/memberships/${value._id}/delete`}
      onClick={e => {
        e.preventDefault();
        // eslint-disable-next-line no-alert
        const shouldDelete = window.confirm(
          "Members should only be deleted once their PayPal subscription is inactive. Are you sure you want to delete this member? This action can't be reversed."
        );

        if (shouldDelete) {
          window.location.href = e.target.getAttribute('href');
        }
      }}
    >
      <i className="fa fa-trash icon--delete gs-u-mr" />
      Remove
    </a>
  );

  const paymentLinkNode = value.partnerMemberId ? (
    <a
      href={`/memberships/${value._id}/${value.partnerMemberId}/payment`}
      target="_blank"
    >
      <i className="fa fa-money gs-u-mr" />
      Payment
    </a>
  ) : (
    <a href={`/memberships/${value._id}/payment`} target="_blank">
      <i className="fa fa-money gs-u-mr" />
      Payment
    </a>
  );

  return (
    <>
      {paypalNode}
      {deleteNode}
      {paymentLinkNode}
    </>
  );
}

const membersColumns = [
  {
    Header: 'First name',
    accessor: 'firstName',
  },
  {
    Header: 'Surname',
    accessor: 'surName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Type',
    accessor: 'membershipType',
  },
  {
    Header: 'Level',
    accessor: 'membershipLevel',
  },
  {
    Header: 'Joined',
    accessor: 'created_date',
    Cell: ({ value }) => {
      return moment(value).format('ddd, MMM Do - H:mm a');
    },
  },
  {
    Header: 'Marketing',
    accessor: 'marketingOptOut',
    Cell: ({ value }) => {
      return value ? (
        <i className="fa fa-minus-circle input-error" aria-hidden="true" />
      ) : (
        <i className="fa fa-check approved" aria-hidden="true" />
      );
    },
  },
  {
    Header: 'Health',
    accessor: 'healthIssues',
    Cell: ({ value }) => {
      return value ? (
        <i className="fa fa-minus-circle input-error" aria-hidden="true" />
      ) : (
        <i className="fa fa-check approved" aria-hidden="true" />
      );
    },
  },
  {
    Header: 'Couple',
    accessor: 'partnerMemberId',
    Cell: ({ value }) => {
      return value ? (
        <i className="fa fa-check approved" aria-hidden="true" />
      ) : null;
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: ActionsCell,
  },
  {
    Header: () => null,
    id: 'isValidMember',
    Cell: ({ row }) => (
      <span>
        {!row.original.actions.subscriptionId ? (
          <i
            className="fa fa-exclamation-circle delete"
            title="This member does not have an active subscription"
          />
        ) : (
          <i
            className="fa fa-check-circle approved"
            title="This member is a happy, paying member"
          />
        )}
      </span>
    ),
  },
  {
    Header: () => null,
    id: 'expander', // It needs an ID
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <i className="fa fa-chevron-down" />
        ) : (
          <i className="fa fa-chevron-right" />
        )}
      </span>
    ),
  },
];

const mapStateToProps = state => ({
  members: transformMembers(get(state, 'members.list', [])),
});

function renderRowSubComponent({ row, subscriptionId, setSubscriptionId }) {
  const {
    original: { actions: member },
  } = row;
  const healthIssues = getHealthIssues(member);
  return (
    <div>
      <div className="membership-details__content gs-u-p++ gel-1/1">
        <ul className="membership-details__list gel-1/1">
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">ID</span>
            <span className="gel-pica">{member._id}</span>
          </li>
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">
              Address
            </span>
            <span className="gel-pica">{member.address}</span>
          </li>
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">
              Phone
            </span>
            <span className="gel-pica">{member.phone}</span>
          </li>
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">
              Subscription ID
            </span>
            <span className="gel-pica">{member.subscriptionId || 'N/A'}</span>
            {member.subscriptionId && (
              <a
                href={`/api/members/${member._id}/unsubscribe`}
                title="Delete this members subscription from our system"
                onClick={e => {
                  e.preventDefault();
                  if (
                    window.confirm(
                      'Are you sure you want to delete this subscription?'
                    )
                  ) {
                    window.location.href = `/api/memberships/${member._id}/unsubscribe?ptrt=${window.location.pathname}`;
                  }
                }}
              >
                <b className="delete gs-u-ml">
                  <i className="fa fa-trash" />
                </b>
              </a>
            )}
            <EditSubscriptionId
              membership={member}
              subscriptionId={subscriptionId}
              setSubscriptionId={setSubscriptionId || ''}
            />
          </li>
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">
              Next of Kin
            </span>
            <span className="gel-pica">
              {member.nextOfKinName} - {member.nextOfKinPhone}
            </span>
          </li>
        </ul>
        {healthIssues.length > 0 && (
          <div className="membership-details__health">
            <h3 className="gs-u-mt+ gs-u-mb">Medical information</h3>
            <ul>
              {healthIssues.map(issue => {
                return (
                  <li className="gs-u-mb" key={issue.question}>
                    <b>{issue.question}</b>
                    <p>{issue.info}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * MembersTable component
 *
 * @param {object} props
 */
const MembersTable = ({ members, title }) => {
  if (!members.length) {
    return (
      <div className="bookings">
        <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
          {title}
        </h2>
        <p>
          Looks like there's currently no members. Check back later to see if
          any come through.
        </p>
      </div>
    );
  }

  const data = useMemo(() => members, []);
  const columns = useMemo(() => membersColumns, []);
  const tableInstance = useTable({ columns, data }, useSortBy, useExpanded);
  const [subscriptionId, setSubscriptionId] = useState();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = tableInstance;

  return (
    <div className="bookings gel-wrap gel-wrap--large classes__wrapper">
      <table {...getTableProps()}>
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr className="table-col" {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th
                  className="gel-pica-bold table-col gs-u-p gs-u-p+@l"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {// Render the header
                  column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fa fa-chevron-down gs-u-ml-" />
                      ) : (
                        <i className="fa fa-chevron-up gs-u-ml-" />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td
                        className="gel-long-primer gs-u-p+"
                        {...cell.getCellProps()}
                      >
                        {// Render the cell contents
                        cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                      {renderRowSubComponent({
                        row,
                        subscriptionId,
                        setSubscriptionId,
                      })}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

MembersTable.defaultProps = {
  members: [],
  title: 'Members',
};

MembersTable.propTypes = {
  members: PropTypes.array,
  title: PropTypes.string,
};

export default connect(mapStateToProps)(MembersTable);
